import { useState } from "react";
import { Label } from '@fluentui/react';
import { FiHelpCircle } from 'react-icons/fi'

import styles from "./ResponseLengthSlider.module.css";

interface Props {
    className?: string;
    onChange: (newValue: number) => void;
    value?: number;
    useDarkMode: boolean;
}

const tooltipHtml = `
This parameter determines the approximate length of the response from the model.<br />
<b>Succinct</b> - 1024 tokens<br />
<b>Standard</b> - 2048 tokens<br />
<b>Thorough</b> - 3072 tokens`

export const ResponseLengthSlider = ({ className, onChange, value = 2048, useDarkMode }: Props) => {
    const [sliderValue, setSliderValue] = useState<number>(value);
    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value);
        setSliderValue(newValue);
        onChange(newValue);
    };

    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Label>Response length&nbsp;
                <FiHelpCircle
                    data-tooltip-id="ResponseLength-tooltip"
                    data-tooltip-html={tooltipHtml}>
                </FiHelpCircle>
            </Label>
            <input
                className={useDarkMode ? styles.sliderDark : styles.slider}
                type="range"
                value={sliderValue}
                step={1024}
                min={1024}
                max={3072}
                onChange={handleSliderChange}
            />
            <div className={styles.sliderLabels}>
                <Label className={styles.label} style={{ textAlign: 'left' }}>Succinct</Label>
                <Label className={styles.label} style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>Standard</Label>
                <Label className={styles.label} style={{ textAlign: 'right' }}>Thorough</Label>
            </div>
        </div>
    );
};
