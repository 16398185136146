import { useEffect } from "react";
import { useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import { ClipboardCode24Filled, ClipboardCheckmark24Filled } from "@fluentui/react-icons";
import { useMsal } from "@azure/msal-react";
import DOMPurify from "dompurify";

import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark, materialLight } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import styles from "./Answer.module.css";

import { getToken } from "../../authConfig";
import { CitationLink, ChatAppResponse, getCitationFilePath, ExportRequest, exportAnswer } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { AnswerOptionsPanel } from "./AnswerOptionsPanel";

interface Props {
    conversationId: string;
    question?: string;
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string, sourcePath: string, pageNumber: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    onSettingsClick: () => void;
    onRegenerateClick: () => void;
    onFeedbackSubmitted: (conversation_id: string, request_id: string, sentiment: string, comments?: string) => void;
    useDarkMode: boolean;
};

export const Answer = ({
    conversationId,
    question,
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onSettingsClick,
    onRegenerateClick,
    onFeedbackSubmitted,
    useDarkMode
}: Props) => {
    const client = useMsal().instance;
    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const citation_lookup = answer.choices[0].context.citation_lookup;
    const request_id = answer.request_id;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, citation_lookup), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const [isRequestCopied, setIsRequestCopied] = useState(false);
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

    // Set sup colours based on theme mode
    useEffect(() => {
        if (useDarkMode) {
            document.documentElement.style.setProperty('--sup-color', '#ffffff');
            document.documentElement.style.setProperty('--sup-background', '#284f77');
        } else {
            document.documentElement.style.setProperty('--sup-color', '#123bb6');
            document.documentElement.style.setProperty('--sup-background', '#d1dbfa');
        }
    }, [useDarkMode]);

    let citationLinks: CitationLink[] = [];
    if (parsedAnswer.citations.length > 0) {
        parsedAnswer.citations.forEach((x, i) => {
            const path = getCitationFilePath(x);
            const originalFile = x.split("/")[1];
            const pageNumbers = parsedAnswer.pageNumbers[x];
            const sourceFiles = parsedAnswer.sourceFiles[x];
            const linkName = `${originalFile} ${!isNaN(pageNumbers) ? `(Page ${pageNumbers})` : ''}`;

            citationLinks.push({
                key: i,
                sourceFile: sourceFiles,
                pageNumber: pageNumbers,
                title: originalFile,
                onClick: () => onCitationClicked(path, sourceFiles as any, pageNumbers as any),
                label: `${++i}. ${linkName}`,
            });
        });
    };

    const onExportClick = async () => {
        try {
            const request: ExportRequest = {
                request_id: request_id,
                question: question as string,
                answer: sanitizedAnswerHtml,
                citations: citationLinks,
            };
            const token = client ? await getToken(client) : undefined;
            return exportAnswer(token, request);
        } catch (error) {
            console.log(error);
        }
    };

    function removeHtmlTags(html: string) {
        return html.replace(/<a\b[^>]*>(.*?)<\/a>/gs, "");
    }; // remove the HTML from the answer for copy to clipboard

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const onCopyAnswerClick = async () => {
        try {
            const text = removeHtmlTags(sanitizedAnswerHtml)
            await handleCopyToClipboard(text);
        } catch (error) {
            console.log(error);
        }
    };

    const onCopyRequestIdClick = async (text: string) => {
        try {
            handleCopyToClipboard(text);

            setIsRequestCopied(true);
            setTimeout(() => {
                setIsRequestCopied(false);
            }, 2000); // Change back after 2 seconds

        } catch (error) {
            console.log(error);
        }
    };

    const handleFeedbackSubmit = async (sentiment: string, comments?: string) => {
        onFeedbackSubmitted(conversationId, request_id, sentiment, comments);
        setIsFeedbackSubmitted(true);
    };

    useEffect(() => {
        const handleLinkClick = (event: MouseEvent) => {
            event.preventDefault();
            const anchorElement = event.currentTarget as HTMLAnchorElement;

            const path = anchorElement.getAttribute('data-path');
            const sourcePath = anchorElement.getAttribute('data-source-path');
            const pageNumber = anchorElement.getAttribute('data-page-number');

            if (path && sourcePath && pageNumber) {
                onCitationClicked(path, sourcePath, pageNumber);
            }
        };

        const links = document.querySelectorAll('.supContainer');
        links.forEach(link => {
            link.addEventListener('click', handleLinkClick as EventListener);
        });

        // Clean up event listeners
        return () => {
            links.forEach(link => {
                link.removeEventListener('click', handleLinkClick as EventListener);
            });
        };
    }, []);


    function CopyCodeButton({ children }: { children: string | string[] }) {
        const [isCodeCopied, setIsCodeCopied] = useState(false);
        const text = Array.isArray(children) ? children.join('') : children;

        const handleClick = async () => {
            navigator.clipboard.writeText(text);

            setIsCodeCopied(true);
            setTimeout(() => {
                setIsCodeCopied(false);
            }, 2000); // Change back after 2 seconds
        }

        return (
            <div
                className={styles.codeCopyButton}
                onClick={handleClick}
                title="Copy code block to clipboard" >
                {isCodeCopied ? <ClipboardCheckmark24Filled /> : <ClipboardCode24Filled />}
            </div>
        )
    };

    function MarkdownRenderer({ className, content }: { className: string, content: string }) {
        return (
            <Markdown
                className={className}
                children={content}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                components={{
                    code({ children, className, node, ...props }) {
                        const hasLang = /language-(\w+)/.exec(className || '');
                        const language = hasLang ? hasLang[1] : undefined;
                        return hasLang ? (
                            <div className={styles.answerCodeBlock}>
                                <CopyCodeButton children={String(children).replace(/\n$/, '')} />
                                <SyntaxHighlighter
                                    language={language}
                                    style={useDarkMode ? materialDark : materialLight}
                                    wrapLongLines
                                    children={String(children).replace(/\n$/, '')}
                                />
                            </div>
                        ) : (
                            <code {...props} className={className}>
                                {children}
                            </code>
                        )
                    }
                }}
            />
        );
    };

    return (
        <Stack className={`${useDarkMode ? styles.answerContainerDark : styles.answerContainer} ${isSelected ? styles.selected : ''}`}
            verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon useDarkMode={useDarkMode} />
                    <div>
                        <IconButton
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.choices[0].context.thoughts?.length}
                        />
                        <IconButton
                            iconProps={{ iconName: "Documentation" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.choices[0].context.data_points?.length}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <MarkdownRenderer className={styles.answerText} content={parsedAnswer.answerHtml} />
            </Stack.Item>

            {!!citationLinks.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {citationLinks.map((link, i) => {
                            const { title, onClick, label } = link;
                            return (
                                <a key={i} className={useDarkMode ? styles.citationDark : styles.citation} title={title} onClick={onClick}>
                                    {label}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!followupQuestions.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={useDarkMode ? styles.followupQuestionDark : styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            <Stack.Item>
                <div className={`${styles.answerTextRequestId} ${isRequestCopied ? styles.disabled : ''}`}
                    onClick={() => onCopyRequestIdClick(`Conversation ID: ${conversationId}\nRequest ID: ${request_id}`)}>
                    <IconButton
                        iconProps={{ iconName: "Copy" }}
                        title="Copy Request ID"
                        ariaLabel="Copy Request ID"
                    />
                    <span>
                        {isRequestCopied ? 'Copied!' : 'Copy Request ID'}
                    </span>
                </div>
            </Stack.Item>
            <Stack.Item align="center">
                <AnswerOptionsPanel
                    onSettingsClick={onSettingsClick}
                    onRegenerateClick={onRegenerateClick}
                    onExportClick={onExportClick}
                    onCopyAnswerClick={onCopyAnswerClick}
                    onFeedbackSubmitted={(s, f) => handleFeedbackSubmit(s, f)}
                    isFeedbackSubmitted={isFeedbackSubmitted}
                    isStreaming={isStreaming}
                    useDarkMode={useDarkMode}
                />
            </Stack.Item>
        </Stack>
    );
};