import { Stack } from "@fluentui/react";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { stackoverflowDark, stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";

import styles from "./AnalysisPanel.module.css";

import { Thoughts } from "../../api";

interface Props {
    thoughts: Thoughts[];
    useDarkMode: boolean;
}

SyntaxHighlighter.registerLanguage('json', json);

export const ThoughtProcess = ({ thoughts, useDarkMode }: Props) => {
    return (
        <ul className={styles.thoughtList}>
            {thoughts.map((t, ind) => {
                return (
                    <li className={styles.thoughtListItem} key={ind}>
                        <div className={styles.thoughtStep}>{t.title}</div>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            {t.props &&
                                (Object.keys(t.props) || []).map((k: any) => (
                                    <span className={useDarkMode ? styles.thoughtPropsDark : styles.thoughtProps}>
                                        {k}: {JSON.stringify(t.props?.[k])}
                                    </span>
                                ))}
                        </Stack>
                        {Array.isArray(t.description) ? (
                            <SyntaxHighlighter
                                className={styles.thoughtCodeBlock}
                                language="json"
                                style={useDarkMode ? stackoverflowDark : stackoverflowLight}
                                wrapLongLines>
                                {JSON.stringify(t.description, null, 2)}
                            </SyntaxHighlighter>
                        ) : (
                            <div>{t.description}</div>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};