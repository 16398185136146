import React from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import { initializeIcons } from "@fluentui/react";

import "./index.css";
import 'react-toastify/dist/ReactToastify.css';

initializeIcons();

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    const container = document.getElementById("root") as HTMLElement;
    const root = ReactDOM.createRoot(container);

    root.render(
        <React.StrictMode>
            <App msalInstance={msalInstance} />
        </React.StrictMode>
    );
});