import { useContext, useEffect, useState } from 'react';
import { getToken } from '../../authConfig';
import { useMsal } from '@azure/msal-react';
import { Stack, Spinner, SpinnerSize, Icon, PrimaryButton } from '@fluentui/react';
import { v4 as uuidv4 } from 'uuid';

import styles from "./FAQ.module.css";

import { UserContext } from "../../components/UserContext";
import { listFaqs, FaqSummary, FaqItem, UserData } from "../../api";
import FaqAnswer from "./FaqAnswer";

const FAQ = () => {
    const client = useMsal().instance;
    const userContext = useContext(UserContext);
    const userData = userContext.userData as UserData;
    const [faqs, setFaqs] = useState<FaqSummary[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [unsavedChanges, setUnsavedChanges] = useState<Record<string, boolean>>({});

    useEffect(() => {
        async function fetchFaqData() {
            try {
                const token = client ? await getToken(client) : undefined;
                const response = await listFaqs(token);
                const sortedFaqs = response.sort((a, b) => a.display_order - b.display_order);
                setFaqs(sortedFaqs);
            } catch (error) {
                console.error('Error fetching FAQ:', error);
            }
            setIsLoading(false);
        }
        fetchFaqData();
    }, []);

    const toggleExpand = (faqId: string) => {
        if (unsavedChanges[faqId]) {
            if (!window.confirm("You have unsaved changes. Are you sure you want to discard them?")) {
                return;
            }
        }

        const newExpandedItems = new Set(expandedItems);
        if (newExpandedItems.has(faqId)) {
            newExpandedItems.delete(faqId);
            const newUnsavedChanges = { ...unsavedChanges };
            delete newUnsavedChanges[faqId]; // Clear unsaved changes tracking for this FAQ
            setUnsavedChanges(newUnsavedChanges);
        } else {
            newExpandedItems.add(faqId);
        }
        setExpandedItems(newExpandedItems);
    };

    const createNewFaq = () => {
        const newFaqId = uuidv4();
        const newFaq: FaqItem = {
            faq_id: newFaqId,
            question: 'New FAQ',
            answer: '',
            display_order: -1
        };

        setFaqs(prevFaqs => [newFaq, ...prevFaqs].sort((a, b) => a.display_order - b.display_order));
        setExpandedItems(new Set([newFaqId])); // Automatically expand the new FAQ entry for editing
        setUnsavedChanges(prev => ({ ...prev, [newFaqId]: true })); // Mark as having unsaved changes
    };

    return (
        <Stack tokens={{ childrenGap: 10 }} className={styles.faqContainer}>
            <h1>Frequently Asked Questions</h1>
            {isLoading && <Spinner size={SpinnerSize.large} label="Loading FAQs..." ariaLive="assertive" />}
            {!isLoading && userData.is_admin &&
                <Stack.Item className={styles.buttonContainer}>
                    <PrimaryButton
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => createNewFaq()}
                        text="Create new FAQ"
                    />
                </Stack.Item>
            }
            {!isLoading && faqs.map((faq, index) => (
                <Stack.Item key={faq.faq_id} className={styles.faqItem}>
                    <h3 onClick={() => toggleExpand(faq.faq_id)}>
                        <Icon
                            iconName={expandedItems.has(faq.faq_id) ? "ChevronUp" : "ChevronDown"}
                            className={styles.faqIcon} />
                        <span>{faq.question}</span>
                    </h3>
                    {expandedItems.has(faq.faq_id) && (
                        <FaqAnswer
                            faqId={faq.faq_id}
                            isNewFaq={faq.display_order === -1}
                            isAdmin={userData.is_admin}
                            useDarkMode={userContext.useDarkMode}
                            onChange={(hasChanges) => setUnsavedChanges(prev => ({ ...prev, [faq.faq_id]: hasChanges }))}
                        />
                    )}
                </Stack.Item>
            ))}
        </Stack>
    );
};

export default FAQ;
