export const lightTheme = {
    semanticColors: {
        bodyBackground: "#f2f2f2",
        bodyText: "#222222"
    },
    palette: {
        themePrimary: '#0078d4',
        themeLighterAlt: '#eff6fc',
        themeLighter: '#deecf9',
        themeLight: '#c7e0f4',
        themeTertiary: '#71afe5',
        themeSecondary: '#2b88d8',
        themeDarkAlt: '#106ebe',
        themeDark: '#005a9e',
        themeDarker: '#004578',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#f2f2f2',
    }
};

export const darkTheme = {
    semanticColors: {
        bodyBackground: "#222222",
        bodyText: "#f2f2f2",
        link: "#ffffff"
    },
    palette: {
        themePrimary: '#2899f5',
        themeLighterAlt: '#021a2f',
        themeLighter: '#052d47',
        themeLight: '#094763',
        themeTertiary: '#1081c2',
        themeSecondary: '#1d71d8',
        themeDarkAlt: '#46a2f7',
        themeDark: '#67aff8',
        themeDarker: '#8fc3fa',
        neutralLighterAlt: '#121212',
        neutralLighter: '#151515',
        neutralLight: '#202020',
        neutralQuaternaryAlt: '#2a2a2a',
        neutralQuaternary: '#2f2f2f',
        neutralTertiaryAlt: '#3a3a3a',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#f2f2f2',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#1b1b1b',
    }
};