import { useState } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { AuthError, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { DefaultButton, PrimaryButton, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';

import styles from "./App.module.css";
import Coeus from "./assets/coeus.png";

import { getRedirectUri, loginRequest } from "./authConfig";
import { UserProvider } from "./components/UserContext";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Content from "./pages/content/Content";
import FAQ from "./pages/faq/FAQ"
import Terms from "./pages/terms/Terms"
import ViewDocument from "./pages/document/ViewDocument"

const MsalError = (props: { error: AuthError | null }) => {
    const { instance } = useMsal();
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => setIsExpanded(!isExpanded);

    const retryLogin = () => {
        instance
            .loginRedirect({
                ...loginRequest,
                redirectUri: getRedirectUri()
            })
            .catch(error => console.log(error));
    };

    return (
        <Stack className={styles.container} tokens={{ childrenGap: 10 }} styles={{ root: { margin: 20 } }}>
            <img src={Coeus} alt="Coeus Logo" className={styles.chatLogo} />
            <Text variant="xLarge">Authentication Error</Text>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton
                    className={styles.button}
                    onClick={toggleExpand}
                    iconProps={{ iconName: 'Info' }}>
                    {isExpanded ? 'Hide Details' : 'Show Details'}
                </DefaultButton>
                <PrimaryButton
                    className={styles.button}
                    onClick={retryLogin}
                    iconProps={{ iconName: 'Refresh' }}>
                    Retry
                </PrimaryButton>
            </Stack>
            {isExpanded && (
                <Text><b>Error Message:</b> {props.error?.errorMessage ?? "An unknown error occurred."}</Text>
            )}
        </Stack>
    );
};

const MsalLoading = () => {
    return (
        <div className={styles.container}>
            <img src={Coeus} alt="Coeus Logo" className={styles.chatLogo} />
            <div className={styles.spinnerContainer}>
                <Spinner size={SpinnerSize.large} label="Please wait" ariaLive="assertive" />
            </div>
        </div>
    );
};

const App = ({ msalInstance }: { msalInstance: PublicClientApplication; }) => {
    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                errorComponent={MsalError}
                loadingComponent={MsalLoading}>
                <UserProvider>
                    <HashRouter>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Navigate replace to="/Chat" />} />
                                <Route path="Chat" element={<Chat />} />
                                <Route path="Chat/:conversationId" element={<Chat />} />
                                <Route path="Content" element={<Content />} />
                                <Route path="FAQ" element={<FAQ />} />
                                <Route path="Terms" element={<Terms />} />
                                <Route path="ViewDocument/*" element={<ViewDocument />} />
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </HashRouter>
                </UserProvider >
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};

export default App;