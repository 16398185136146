import { toast } from 'react-toastify';

export function handleError(error: any, message: string) {
    let errorMessage = message;
    // Determine the specific error message
    let specificErrorMessage = "An unexpected error occurred";
    if (typeof error === 'string') {
        specificErrorMessage = error;
    } else if (error instanceof Error) {
        specificErrorMessage = error.message;
    } else if (typeof error === 'object' && error !== null) {
        const err = error as { response?: { data?: { error?: string; message?: string } } };
        if (err.response && err.response.data) {
            specificErrorMessage = err.response.data.error || err.response.data.message || specificErrorMessage;
        }
    }
    // Display the error message as a toast notification
    errorMessage = `${errorMessage}: ${specificErrorMessage}`;
    toast.error(errorMessage);
};

export function handleSuccess(message: string) {
    toast.success(message);
}