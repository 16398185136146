import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { BlobServiceClient } from '@azure/storage-blob';
import { DefaultButton, Dialog, DialogType, DialogFooter, PrimaryButton, Separator, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import {
    MDXEditor, diffSourcePlugin, headingsPlugin, imagePlugin, linkDialogPlugin, linkPlugin,
    listsPlugin, markdownShortcutPlugin, quotePlugin, tablePlugin, toolbarPlugin
} from '@mdxeditor/editor'
import {
    BoldItalicUnderlineToggles, CreateLink, CodeToggle, DiffSourceToggleWrapper,
    InsertImage, InsertTable, ListsToggle, UndoRedo
} from '@mdxeditor/editor';

import '@mdxeditor/editor/style.css'
import styles from "./FAQ.module.css";

import { handleError } from '../../toaster';
import { getFaq, FaqItem, updateFaq, getBlobClientUrl } from "../../api";
import { getToken } from '../../authConfig';

interface Props {
    faqId: string;
    isNewFaq: boolean;
    isAdmin: boolean;
    useDarkMode: boolean;
    onChange: (hasUnsavedChanges: boolean) => void;
};

const Toolbar = () => (
    <DiffSourceToggleWrapper>
        <UndoRedo />
        <Separator />
        <BoldItalicUnderlineToggles />
        <CodeToggle />
        <Separator />
        <ListsToggle />
        <Separator />
        <CreateLink />
        <InsertImage />
        <Separator />
        <InsertTable />
    </DiffSourceToggleWrapper>
);

const FaqAnswer = ({ faqId, isNewFaq, isAdmin, useDarkMode, onChange }: Props) => {
    const client = useMsal().instance;
    const [faqItem, setFaqItem] = useState<FaqItem | null>(null);
    const [editedFaqItem, setEditedFaqItem] = useState<FaqItem | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaveDialogVisible, setIsSaveDialogVisible] = useState(false);
    const themeClass = useDarkMode ? styles.darkEditor : styles.lightEditor;

    useEffect(() => {
        const fetchFaqItem = async () => {
            const token = client ? await getToken(client) : undefined;
            setIsLoading(true);
            try {
                let item: FaqItem;
                if (isNewFaq) {
                    item = {
                        faq_id: faqId,
                        question: 'New FAQ',
                        answer: '',
                        display_order: -1,
                    }
                }
                else {
                    item = await getFaq(token, faqId);
                }
                setFaqItem(item);
                setEditedFaqItem(item);
            } catch (error) {
                handleError(error, `Error fetching FAQ item ${faqId}`);
                setFaqItem(null);
                setEditedFaqItem(null);
            }
            setIsLoading(false);
        };

        fetchFaqItem();

        if (isNewFaq) {
            // Open edit mode if creating a new FAQ
            setIsEditing(true);
        };
    }, [faqId]);

    const handleChange = (content: string) => {
        if (!editedFaqItem) return;

        // Update the local state for the edited FAQ item
        setEditedFaqItem({ ...editedFaqItem, answer: content });

        // Notify the parent component about the unsaved changes
        onChange(true);
    };

    const handleImageUpload = (image: File): Promise<string> => {
        // Return a new promise
        return new Promise(async (resolve, reject) => {
            try {
                const token = client ? await getToken(client) : undefined;
                const blobClientUrl = await getBlobClientUrl(token);
                const blobServiceClient = new BlobServiceClient(blobClientUrl);
                const containerClient = blobServiceClient.getContainerClient("website");

                const filePath = `images/${image.name}`;
                const blobClient = containerClient.getBlockBlobClient(filePath);
                const options = { blobHTTPHeaders: { blobContentType: image.type } };

                await blobClient.uploadData(image, options);

                resolve(filePath);
            } catch (error) {
                handleError(error, "Error uploading image");
                reject(error);
            }
        });
    };

    const handleSave = async () => {
        if (!editedFaqItem || !isAdmin) return;

        setIsSaving(true);
        try {
            const token = client ? await getToken(client) : undefined;
            await updateFaq(token, editedFaqItem);
            setFaqItem(editedFaqItem);
        } catch (error) {
            handleError(error, `Error saving FAQ item ${faqId}`);
        }
        setIsEditing(false);
        setIsSaving(false);
        setIsSaveDialogVisible(false);
    };

    if (isLoading) return <Spinner size={SpinnerSize.small} label="Loading FAQ..." />;
    if (isSaving) return <Spinner size={SpinnerSize.small} label="Saving FAQ..." />;
    if (!faqItem) return null;

    return (
        <div>
            {isEditing && editedFaqItem ? (
                <div>
                    <MDXEditor
                        className={`${styles.mdxeditor} ${themeClass}`}
                        markdown={editedFaqItem.answer}
                        onChange={handleChange}
                        plugins={[
                            toolbarPlugin({ toolbarContents: () => <Toolbar /> }),
                            listsPlugin(),
                            quotePlugin(),
                            headingsPlugin(),
                            linkPlugin(),
                            linkDialogPlugin(),
                            imagePlugin({ imageUploadHandler: handleImageUpload }),
                            tablePlugin(),
                            markdownShortcutPlugin(),
                            diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: faqItem.answer }),
                        ]}
                    />
                    <div className={styles.buttonContainer}>
                        <PrimaryButton
                            iconProps={{ iconName: 'Save' }}
                            onClick={() => setIsSaveDialogVisible(true)}
                            text="Save"
                        />
                        <DefaultButton
                            iconProps={{ iconName: 'Cancel' }}
                            onClick={() => setIsEditing(false)}
                            text="Cancel"
                            style={{ marginLeft: '10px' }}
                        />
                    </div>
                    <Dialog
                        hidden={!isSaveDialogVisible}
                        onDismiss={() => setIsSaveDialogVisible(false)}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: 'Save FAQ'
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: {
                                main: {
                                    maxWidth: '750px !important',
                                    minWidth: '500px !important',
                                },
                            },
                        }}>
                        <TextField
                            label="FAQ Question"
                            value={editedFaqItem?.question || ''}
                            onChange={(e, newValue) => setEditedFaqItem(current =>
                                current ? ({ ...current, question: newValue || '' }) : null
                            )}
                            required
                        />
                        <TextField
                            label="Display Order"
                            type="number"
                            value={editedFaqItem?.display_order?.toString() || ''}
                            onChange={(e, newValue) => setEditedFaqItem(current =>
                                current ? ({
                                    ...current,
                                    display_order: newValue ? parseInt(newValue, 10) : 0,
                                }) : null
                            )}
                            required
                        />
                        <DialogFooter className={styles.buttonContainer}>
                            <PrimaryButton
                                iconProps={{ iconName: 'Save' }}
                                disabled={isSaving || editedFaqItem?.question.length == 0}
                                onClick={handleSave}
                                text="Save"
                            />
                            <DefaultButton
                                iconProps={{ iconName: 'Cancel' }}
                                disabled={isSaving}
                                onClick={() => setIsSaveDialogVisible(false)}
                                text="Cancel"
                            />
                        </DialogFooter>
                    </Dialog>
                </div>
            ) : (
                <Markdown rehypePlugins={[rehypeRaw]}>{faqItem.answer}</Markdown>
            )}
            {isAdmin && !isEditing && (
                <div className={styles.buttonContainer}>
                    <PrimaryButton
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => setIsEditing(!isEditing)}
                        text="Edit" />
                </div>
            )}

        </div>
    );
};

export default FaqAnswer;