import { useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { ContextualMenu, Persona, PersonaSize, PersonaInitialsColor, IContextualMenuItem } from '@fluentui/react';

import styles from "./UserInfoMenu.module.css";

import { UserData, logout } from '../../api'

interface Props {
    userData: UserData;
}

export const UserInfoMenu = ({ userData }: Props) => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [showContextMenu, setShowContextMenu] = useState(false);
    const personaRef = useRef(null);

    const onPersonaClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setShowContextMenu(true);
    };

    const onHideContextMenu = () => {
        setShowContextMenu(false);
    };

    const handleLogout = () => {
        if (activeAccount) {
            console.log(activeAccount)
            instance
                .logoutRedirect({
                    postLogoutRedirectUri: "/logout", 
                    account: instance.getActiveAccount()
                })
                .catch(error => console.log(error));
        } else {
            logout();
        }
    };
    
    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        onHideContextMenu();
    };

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'logout',
            iconProps: { iconName: 'SignOut' },
            text: 'Logout',
            onClick: () => handleLogout(),
            className: styles.menuItem
        },
        {
            key: 'copySessionInfoToClipboard',
            iconProps: { iconName: 'Copy' },
            text: 'Copy Session Info to Clipboard',
            onClick: () => handleCopyToClipboard(`User ID: ${userData.userPrincipalName}\nSession ID: ${userData.session_id}`),
        },
    ];

    return (
        <div>
            {userData && (
                <>
                    <Persona
                        className={styles.persona}
                        text={userData.displayName}
                        imageUrl={userData.user_photo ?? undefined}
                        size={PersonaSize.size48}
                        initialsColor={PersonaInitialsColor.blue}
                        onClick={onPersonaClick}
                        ref={personaRef}
                    />
                    <ContextualMenu
                        items={menuItems}
                        hidden={!showContextMenu}
                        target={personaRef}
                        onItemClick={onHideContextMenu}
                        onDismiss={onHideContextMenu}
                    />
                </>
            )}
        </div>
    );
};
