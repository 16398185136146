import React, { createContext, useState, useEffect, ReactNode } from "react";
import { Spinner, SpinnerSize } from '@fluentui/react';

import styles from "./UserContent.module.css";
import Coeus from "../../assets/coeus.png";
import { getToken } from "../../authConfig";
import { getUserData, UserData } from '../../api';
import { useMsal } from "@azure/msal-react";
import { handleError } from "../../toaster";


interface UserContextType {
    userData: UserData | null;
    shouldRefreshContext: boolean;
    setShouldRefreshContext: React.Dispatch<React.SetStateAction<boolean>>;
    useDarkMode: boolean;
    setUseDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserContext = createContext<UserContextType>({
    userData: null,
    shouldRefreshContext: false,
    setShouldRefreshContext: () => { },
    useDarkMode: false,
    setUseDarkMode: () => { },
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const client = useMsal().instance;
    const [userData, setUserData] = useState<UserData | null>(null);
    const [shouldRefreshContext, setShouldRefreshContext] = useState(false);
    const [useDarkMode, setUseDarkMode] = useState(() => {
        const localPreference = localStorage.getItem('useDarkMode');
        return localPreference !== null ? JSON.parse(localPreference) : window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    useEffect(() => {
        localStorage.setItem('useDarkMode', JSON.stringify(useDarkMode));
    }, [useDarkMode]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e: MediaQueryListEvent) => {
            setUseDarkMode(e.matches);
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    async function fetchUserData() {
        try {
            const token = await getToken(client);
            const data: UserData = await getUserData(token);
            setUserData(data);
        } catch (error) {
            handleError(error, "Error fetching user data");
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [shouldRefreshContext]);

    if (!userData) {
        return (
            <div className={styles.container}>
                <img src={Coeus} alt="Coeus Logo" className={styles.chatLogo} />
                <div className={styles.spinnerContainer}>
                    <Spinner size={SpinnerSize.large} label="Loading user data..." ariaLive="assertive" />
                </div>
            </div>
        );
    };

    return (
        <UserContext.Provider value={{
            userData,
            shouldRefreshContext,
            setShouldRefreshContext,
            useDarkMode,
            setUseDarkMode,
        }}>
            {children}
        </UserContext.Provider>
    );
};